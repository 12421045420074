@import "/src/source/mixin.scss";
.main {
  padding: 0 0 100px;
  .title {
    // position: absolute;
    margin-top: 37px;
    width: 300px;
    background: rgba(31, 120, 225, 1);
    padding: 0 15px;
    border-radius: 0 82px 82px 0;
    // top: 100px;
    // left: -105px;
    text-align: end;
    @include screen(426px) {
      width: 426px;
      border-radius: 0;
      text-align: center;
      margin: 0;
    }
    @include screen(376px) {
      width: 375px;
    }
    @include screen(320px) {
      width: 320px;
    }

    h1 {
      color: #fff;
      // font-size: 28px;
      font-size: 52px;
      font-weight: 300;
      line-height: 78px;
      @include screen(376px){
        font-size: 29px;
        line-height: 54px;
      }
    }
  }
  .cards {
    padding-top: 57px;
    display: flex;
    flex-direction: column;
    gap: 57px;
    @include screen(426px){
      gap: 30px;
      padding-top: 20px;
    }
    img {
      width: 429px;
      height: 260px;
      border-radius: 5px;
      @include screen(768px){
        width: 350px;
        height: 200px;
      }
      @include screen(376px){
        width: 300px;
        height: 160px;
      }
    }
    .divType {
      width: 1108px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center; 
      padding: 0 40px; 
      @include screen(1024px){
        width: 100%;
      }
      @include screen(426px){
        flex-direction: column;
      }
      @include screen(376px){
        gap: 20px;
      }
      div {
        padding: 20px 40px;
        width: 470px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        text-align: start;
        @include screen(768px){
          gap: 10px;
        }
        @include screen(376px){
          width: 300px;
          padding: 0;
        }
        h1 {
          // font-family: Futura;
          font-size: 24px;
          font-weight: 700;
          line-height: 23.87px;
          text-align: left;
          color: #6b7280;
          @include screen(768px){
            font-size: 14px;
            line-height: 16px;
          }
        }
        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 21.58px;
          text-align: left;
          color: #6b7280;
          @include screen(768px){
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }
    .divType2 {
      width: 1108px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center; 
      padding: 0 40px; 
      @include screen(1024px){
        width: 100%;
      }
      @include screen(426px){
        flex-direction: column-reverse;
      }
      @include screen(376px){
        gap: 20px;
      }
      div {
        padding: 20px 40px;
        width: 459px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        text-align: start;
        @include screen(768px){
          gap: 10px;
        }
        @include screen(376px){
          width: 300px;
          padding: 0;
        }
        h1 {
          // font-family: Futura;
          font-size: 24px;
          font-weight: 700;
          line-height: 23.87px;
          text-align: left;
          color: #6b7280;
          @include screen(768px){
            font-size: 14px;
            line-height: 16px;
          }
        }
        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 21.58px;
          text-align: left;
          color: #6b7280;@include screen(768px){
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }
  }
}
