
.header {
  background: rgba(0, 0, 0, 0.23);
  display: flex;
  // width: 100vw;
  .header_wrap {
    // width: 1240px;
    margin: 0 auto;
    display: flex;
    // flex-direction: row;
    // gap: 120px;
    padding: 5px;
    align-items: center;
    // justify-content: center;
    z-index: 10;
    .logo{
      display: none;
    }
    .navBar {
      display: flex;
      flex-direction: row;
      gap: 114px;
      align-items: center;
      a {
        position: relative;
        font-size: 18px;
        font-weight: 400;
        color: #fff;
        text-decoration: none;
        transition: color 0.3s ease-in-out;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 1px;
          background-color: #fff;
          transition: width 0.3s ease-in-out;
        }

        &:hover {
          color: #fff;

          &::after {
            width: 100%;
          }
        }
      }

      .square {
        width: 70px;
        height: 70px;
        background: rgba(217, 217, 217, 1);
      }
    }
    .language {
      display: flex;
      gap: 10px;

      button {
        width: 41px;
        border-radius: 40px;
        border: solid 1px rgba(255, 255, 255, 1);
        padding: 5px;
        background: none;
        color: #fff;
        transition: 0.3s ease-in-out;
        &:hover {
          background: #ccc;
        }

        &.active {
          color: #000;
          border: 1px solid #000;
        }
      }
    }

    .burgerMenu {
      display: none;
      
      .burgerButton {
        font-size: 24px;
        color: #fff;
        background: none;
        border: none;
        cursor: pointer;
      }

      .burgerContent {
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: absolute;
        top: 40px;
        right: 0;
        background-color: rgba(50, 48, 48, 0.9);
        padding: 20px;
        a {
          font-size: 18px;
          font-weight: 400;
          color: #fff;
          text-decoration: none;
          &:hover {
            color: #ccc;
          }
        }
      }
    }
    .btn {
      color: #fff;
      width: 100px;
      background: rgba(31, 120, 225, 1);
      font-size: 14px;
      font-weight: 300;
      border: none;
      border-radius: 38px;
      padding: 10px;
      cursor: pointer;
      position: absolute;
      top: 14px;
      right: 130px;
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .header {
    // max-width: 350vh;
    width: 1440px;
    .header_wrap {
      // width: 1010px;
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .header {
    max-width: 1024px;
    // width: 100vw;
    display: flex;
    justify-content: center;
    .header_wrap {
      // width: 100%;
      max-width: 100vw;
      margin: 0 auto;
      .navBar {
        display: flex;
        flex-direction: row;
        gap: 45px;
      }
    }
  }
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .header {
    .header_wrap {
      width: 425px;
      height: 50px;
      .logo{
        display: block  ;
        margin: 10px auto;
      }
      .navBar {
        display: none;
      }
      .language {
        display: none;
      }
      .btn {
        display: none;
      }
      .burgerMenu {
        display: block;
        position: absolute;
        right: 40px;
      }
    }
  }
}
@media screen and (min-width: 375px) and (max-width: 426px) {
  .header {
    .header_wrap {
      width: 375px;
      height: 50px;
      .logo{
        display: block  ;
        margin: 10px auto;
      }
      .navBar {
        display: none;
      }
      .language {
        display: none;
      }
      .btn {
        display: none;
      }
      .burgerMenu {
        display: block;
        position: absolute;
        right: 40px;
      }
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 375px) {
  .header {
    .header_wrap {
      width: 320px;
      height: 50px;
      .logo{
        display: block  ;
        margin: 10px auto;
      }
      .navBar {
        display: none;
      }
      .language {
        display: none;
      }
      .btn {
        display: none;
      }
      .burgerMenu {
        display: block;
        position: absolute;
        right: 40px;
      }
    }
  }
}

