@import "/src/source/mixin.scss";
.main {
  .element::-webkit-scrollbar {
    width: 0;
  }
  ::-webkit-scrollbar {
    width: 0;
  }

  .supplies_wrap {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
      url("./images/Frame 632.png");
    height: 650px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    top: -80px;
    z-index: 1;
    .supples_content {
      padding-top: 90px;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      h2 {
        width: 832px;
        text-align: center;
        font-size: 55px;
        font-weight: 500;
        color: rgba(31, 120, 225, 1);
      }
      p {
        text-align: center;
        width: 735px;
        font-size: 20px;
        font-weight: 400;
        color: #fff;
      }
      button {
        width: 213px;
        font-size: 24px;
        font-weight: 700;
        border-radius: 26px;
        border: none;
        padding: 10px;
        background: rgba(31, 120, 225, 1);
        cursor: pointer;
        color: #fff;
      }
    }
  }
  .swiper {
    min-width: 100%;
    max-width: 100vw;
    position: relative;
    top: -80px;
    padding-top: 70px;
    .swiperBlock {
      width: 100%;
      max-width: 100vw;
      margin: 0 auto;
      .desc {
        width: 1200px;
        display: flex;
        flex-direction: row;
        gap: 135px;
        align-items: center;
        margin: 0 auto;
        .square {
          width: 629px;
          height: 456px;
          background: rgba(82, 82, 82, 1);
        }
        .desc2 {
          display: flex;
          flex-direction: column;
          gap: 5px;
          span {
            font-size: 24px;
            font-weight: 500;
            color: rgba(107, 114, 128, 1);
          }
          p {
            width: 400px;
            font-size: 18px;
            font-weight: 400;
            color: rgba(107, 114, 128, 1);
          }
        }
      }
      .swiper_wrap {
        padding-top: 30px;
        margin-top: 30px;
        text-align: center;
        .cards {
          display: flex;
          flex-direction: row;
          margin: 0 auto;
          gap: 25px;
          width: max-content;
          scrollbar-width: none;
          padding-bottom: 30px;
          .card {
            width: 445px;
            background: rgba(255, 255, 255, 1);
            padding: 22px 27px 22px 27px;
            border-radius: 25px;
            span {
              font-size: 26px;
              font-weight: 700;
              line-height: 39px;
              color: rgba(107, 114, 128, 1);
            }
            p {
              font-size: 16px;
              font-weight: 300;
              line-height: 24px;
              color: rgba(107, 114, 128, 1);
              padding-top: 20px;
              text-align: start;
            }
          }
        }
        button {
          width: 183px;
          font-size: 24px;
          font-weight: 700;
          border-radius: 26px;
          border: none;
          padding: 10px;
          background: rgba(31, 120, 225, 1);
          cursor: pointer;
          color: #fff;
        }
      }
    }
  }
  .questions {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
      url("./images/Frame\ 984.png");
    height: 650px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
    .questions_wrap {
      width: 530px;
      margin: 0 auto;
      text-align: center;
      padding-top: 120px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      h2 {
        color: #fff;
        font-size: 65px;
        font-weight: 800;
      }
      p {
        color: #fff;
        font-size: 30px;
        font-weight: 600;
      }
      button {
        width: 213px;
        font-size: 24px;
        font-weight: 700;
        border-radius: 26px;
        border: none;
        padding: 10px;
        background: rgba(31, 120, 225, 1);
        cursor: pointer;
        color: #fff;
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .main {
    .supplies_wrap {
      max-width: 350vh;
      width: 100%;
      .supples_content {
        width: 980px;
        margin: 0 auto;
      }
    }
    .swiper {
      width: 1024px;
      .swiperBlock {
        width: 980px;
        margin: 40px;
        margin: 0 auto;
        .desc {
          width: 1000px;
          img {
            width: 400px;
          }
        }
        .swiper_wrap {
          padding-top: 30px;
          margin-top: 30px;
          text-align: center;
          .cards {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            
            gap: 25px;
            width: 100%;
            scrollbar-width: none;
            .card {
              // width: 70vh;
              background: rgba(255, 255, 255, 1);
              padding: 22px 27px 22px 27px;
              border-radius: 25px;
              span {
                font-size: 27px;
                font-weight: 700;
                color: rgba(107, 114, 128, 1);
              }
              p {
                font-size: 16px;
                font-weight: 300;
                color: rgba(107, 114, 128, 1);
              }
            }
          }
        }
      }
    }
    .questions {
      max-width: 350vh;
      width: 100%;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .main {
    max-width: 768px;
    width: 100%;
    .supplies_wrap {
      max-width: 768px;
      width: 100%;
      .supples_content {
        padding-top: 150px;
        width: 700px;
        margin: 0 auto;
        gap: 55px;
        h2 {
          width: 500px;
          font-size: 30px;
          font-weight: 500;
          color: rgba(31, 120, 225, 1);
        }
      }
    }
    .swiper {
      width: 768px;
      .swiperBlock {
        max-width: 750px;
        width: 100%;
        .desc {
          display: flex;
          flex-direction: row;
          gap: 20px;
          align-items: center;
          width: 720px;
          img {
            width: 295px;
          }
          .desc2 {
            span {
              font-size: 19px;
            }
            p {
              width: 350px;
              font-size: 14px;
            }
          }
        }
        .swiper_wrap {
          padding-top: 30px;
          margin-top: 30px;
          text-align: center;
          .cards {
            display: flex;
            flex-direction: column;
            gap: 25px;
            width: max-content;
            scrollbar-width: none;
            .card {
              width: 750px;
              background: rgba(255, 255, 255, 1);
              padding: 22px 27px 22px 27px;
              border-radius: 25px;
              span {
                font-size: 27px;
                font-weight: 700;
                color: rgba(107, 114, 128, 1);
              }
              p {
                font-size: 16px;
                font-weight: 300;
                color: rgba(107, 114, 128, 1);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .main {
    max-width: 425px;
    width: 100%;
    .supplies_wrap {
      width: 425px;
      .supples_content {
        width: 400px;
        margin: 0 auto;
        h2 {
          width: 300px;
          font-size: 30px;
        }
        p {
          font-size: 16px;
          width: 300px;
        }
      }
    }
    .swiper {
      width: 425px;
      padding: 50px 0;
      .swiperBlock {
        width: 400px;
        .desc {
          display: flex;
          flex-direction: column;
          gap: 30px;
          align-items: start;
          width: 400px;
          img {
            width: 300px;
            margin: 0 auto;
          }
        }
        .desc2{
          // align-items: c;
        }
        .swiper_wrap {
          padding-top: 30px;
          margin-top: 30px;
          text-align: center;
          .cards {
            display: flex;
            flex-direction: column; /* Ensures column layout */
            gap: 25px;
            width: 100%; /* Make it 100% for better alignment */
            scrollbar-width: none;
            .card {
              width: 100%; /* Full width for each card */
              background: rgba(255, 255, 255, 1);
              padding: 22px 27px 22px 27px;
              border-radius: 25px;
              span {
                font-size: 27px;
                font-weight: 700;
                color: rgba(107, 114, 128, 1);
              }
              p {
                font-size: 16px;
                font-weight: 300;
                color: rgba(107, 114, 128, 1);
              }
            }
          }
        }
      }
    }
    .questions {
      width: 425px;
      height: 500px;
      .questions_wrap {
        width: 400px;
        margin: 0 auto;
        text-align: center;
        padding-top: 120px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        h2 {
          color: #fff;
          font-size: 40px;
          font-weight: 800;
        }
        p {
          color: #fff;
          font-size: 20px;
          font-weight: 600;
        }
        button {
          width: 213px;
          font-size: 24px;
          font-weight: 700;
          border-radius: 26px;
          border: none;
          padding: 10px;
          background: rgba(31, 120, 225, 1);
          cursor: pointer;
          color: #fff;
        }
      }
    }
  }
}
@media screen and  (min-width: 375px) and (max-width: 424px){
  .main {
    max-width: 375px;
    width: 100%;
    .supplies_wrap {
      width: 375px;
      .supples_content {
        width: 350px;
        margin: 0 auto;
        h2 {
          width: 300px;
          font-size: 30px;
        }
        p {
          font-size: 16px;
          width: 300px;
        }
      }
    }
    .swiper {
      width: 375px;
      .swiperBlock {
        width: 350px;
        .desc {
          display: flex;
          flex-direction: column;
          gap: 30px;
          align-items: start;
          width: 350px;
          img {
            width: 350px;
          }
          .desc2{
            p{
              width: 375px;
            }
          }
        }
        .swiper_wrap {
          padding-top: 30px;
          margin-top: 30px;
          text-align: center;
          .cards {
            display: flex;
            flex-direction: column; /* Ensures column layout */
            gap: 25px;
            width: 100%; /* Make it 100% for better alignment */
            scrollbar-width: none;
            .card {
              width: 100%; /* Full width for each card */
              background: rgba(255, 255, 255, 1);
              padding: 22px 27px 22px 27px;
              border-radius: 25px;
              span {
                font-size: 27px;
                font-weight: 700;
                color: rgba(107, 114, 128, 1);
              }
              p {
                font-size: 16px;
                font-weight: 300;
                color: rgba(107, 114, 128, 1);
              }
            }
          }
        }
      }
    }
    .questions {
      width: 375px;
      height: 500px;
      .questions_wrap {
        width: 350px;
        margin: 0 auto;
        text-align: center;
        padding-top: 120px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        h2 {
          color: #fff;
          font-size: 40px;
          font-weight: 800;
        }
        p {
          color: #fff;
          font-size: 20px;
          font-weight: 600;
        }
        button {
          width: 213px;
          font-size: 24px;
          font-weight: 700;
          border-radius: 26px;
          border: none;
          padding: 10px;
          background: rgba(31, 120, 225, 1);
          cursor: pointer;
          color: #fff;
        }
      }
    }
  }
}

@media screen and (min-width: 319px) and (max-width: 374px) {
  .main {
    max-width: 320px;
    width: 100%;
    .supplies_wrap {
      width: 320px;
      height: 550px;
      .supples_content {
        width: 295px;
        margin: 0 auto;
        h2 {
          width: 300px;
          font-size: 24px;
        }
        p {
          font-size: 14px;
          width: 300px;
        }
      }
    }
    .swiper {
      width: 320px;
      .swiperBlock {
        width: 320px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        .desc {
          display: flex;
          flex-direction: column;
          gap: 30px;
          align-items: start;
          width: 295px;
          img {
            width: 295px;
          }
          .desc2{
            // padding: 10px;
            height: auto;
            p{
              font-size: 12px;
              width: 300px;
            }
            span{
              font-size: 18px;
            }
          }
        }
        .swiper_wrap {
          padding-top: 30px;
          margin-top: 30px;
          text-align: center;
          .cards {
            display: flex;
            flex-direction: column; /* Ensures column layout */
            gap: 25px;
            width: 100%; /* Make it 100% for better alignment */
            scrollbar-width: none;
            .card {
              width: 300px; /* Full width for each card */
              background: rgba(255, 255, 255, 1);
              padding: 22px 27px 22px 27px;
              border-radius: 25px;
              span {
                font-size: 18px;
                font-weight: 700;
                color: rgba(107, 114, 128, 1);
              }
              p {
                font-size: 14px;
                font-weight: 300;
                color: rgba(107, 114, 128, 1);
              }
            }
          }
        }
      }
    }
    .questions {
      width: 320px;
      height: 350px;
      .questions_wrap {
        width: 295px;
        margin: 0 auto;
        text-align: center;
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        h2 {
          color: #fff;
          font-size: 40px;
          font-weight: 800;
        }
        p {
          color: #fff;
          font-size: 20px;
          font-weight: 600;
        }
        button {
          width: 213px;
          font-size: 24px;
          font-weight: 700;
          border-radius: 26px;
          border: none;
          padding: 10px;
          background: rgba(31, 120, 225, 1);
          cursor: pointer;
          color: #fff;
        }
      }
    }
  }
}
