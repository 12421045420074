@import "/src/source/mixin.scss";
.main {
  background-color: #e8e8e8;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;
  position: relative;
  overflow: hidden;
  p,
  li,
  h2,
  h3 {
    color: #6b7280;
  }
  .title {
    // position: absolute;
    margin-top: 37px;
    width: 300px;
    background: rgba(31, 120, 225, 1);
    padding: 0 15px;
    border-radius: 0 82px 82px 0;
    // top: 100px;
    // left: -105px;
    text-align: end;
    @include screen(426px) {
      width: 426px;
      border-radius: 0;
      text-align: center;
      margin: 0;
    }
    @include screen(376px) {
      width: 375px;
    }
    @include screen(320px) {
      width: 320px;
    }

    h1 {
      color: #fff;
      // font-size: 28px;
      font-size: 52px;
      font-weight: 300;
      line-height: 78px;
      @include screen(376px){
        font-size: 29px;
        line-height: 54px;
      }
    }
  }
  .firstBlock {
    width: 100%;
    max-width: 100vw;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding: 0 100px;
    // @include screen(1025px){
    //   width: 1024px;
    // }
    @include screen(1024px){
      padding: 0 20px;
    }
    @include screen(375px){

    }
    h2 {
      padding: 45px 0 39px 0px;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      // margin: 0 auto;
      @include screen(426px) {
        padding: 45px 10px 39px 10px;
        font-size: 22px;
      }
    }
    .divImage {
      display: flex;
      flex-wrap: wrap;
      gap: 43px;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      // flex: 1 1 1 50%;
      @include screen(1025px) {
        gap: 0;
      }
      @include screen(768px) {
        flex-direction: column-reverse;
      }
      p {
        width: 47%;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        height: auto;
        @include screen(768px) {
          width: 80%;
          padding-top: 20px;
        }
        @include screen(426px) {
          width: 90%;
        }
        @include screen(426px) {
          font-size: 13px;
          line-height: 25px;
        }
      }
      img {
        padding: 0 49px 0 49px;
        @include screen(1025px) {
          padding: 0;
        }
        @include screen(426px){
          max-width: 420px;
          padding: 0 10px;
        }
        @include screen(376px){
          max-width: 335px;
        }
        @include screen(320px){
          max-width: 280px;
        }
      }
    }
  }
  .infoWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px 115px 20px;
    div {
      display: flex;
      flex-direction: column;
      max-width: 335px;
      // margin: 0 auto;
      @include screen (1024px){
        max-width: 300px;
      }
      h2 {
        padding: 94px 0 0 0;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        text-align: center;
        @include screen (1024px){
          font-size: 18px;
        }
        @include screen(426px) {
          padding: 56px 0 0 0;
        }
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding-left: 30px;
        li {
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          text-align: left;
          @include screen (1024px){
            font-size: 16px;
          }
        }
        .noLi {
          list-style-type: none;
        }
      }
    }
  }
  .thirdBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 27px;
    padding-bottom: 110px;
    h2 {
      // width: 401px;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
      padding: 10px;
      background-color: #fff;
      border-radius: 15px;
      @include screen(426px) {
        
      }
    }
    .brickDiv {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
      justify-content: center;
      @include screen(426px) {
        gap: 20px;
      }
      div {
        width: 350px;
        display: flex;
        flex-direction: column;
        padding: 14px 38px;
        height: auto;
        background-color: white;
        border-radius: 25px;
        gap: 10px;
        @include screen(321px) {
          width: 320px;
        }

        // text-align: center;
        h3 {
          font-size: 26px;
          font-weight: 600;
          line-height: 28.13px;
          text-align: left;
        }
        p {
          font-size: 16px;
          font-weight: 300;
          line-height: 18.75px;
          text-align: left;
        }
      }
    }
  }
}
