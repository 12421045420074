@import "/src/source/mixin.scss";
.footer {
  background: #fff;
  // overflow: hidden;
  padding-top: 40px;
  .footer_wrap {
    padding-top: 60px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    .span1 {
      padding-top: 20px;
      text-align: center;
      color: rgba(107, 114, 128, 1);
    }
    .footer_content {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      text-align: center;
      padding-bottom: 30px;
      border-bottom: solid 1px rgba(107, 114, 128, 1);
      .someWords {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        .square {
          width: 250px;
          height: 137px;
          background: rgba(217, 217, 217, 1);
        }
        span {
          font-size: 16px;
          font-weight: 400;
          color: rgba(107, 114, 128, 1);
          width: 200px;
        }
      }
      .menu {
        text-align: center;

        p {
          font-size: 18px;
          font-weight: 500;
          color: rgba(107, 114, 128, 1);
        }
        nav {
          display: flex;
          flex-direction: column;
          gap: 5px;
          a {
            font-size: 16px;
            font-weight: 400;
            text-decoration: none;
            color: rgba(107, 114, 128, 1);
          }
        }
      }
      .contacts {
        display: flex;
        flex-direction: column;
        gap: 30px;
        text-align: start;
        
        .number {
          display: flex;
          flex-direction: column;
          gap: 5px;
          color: rgba(107, 114, 128, 1);
          .spanBig {
            font-size: 18px;
            font-weight: 500;
            color: rgba(107, 114, 128, 1);
          }
          a {
            color: rgba(107, 114, 128, 1);
            
            text-decoration: none;
          }
        }
        .email {
          display: flex;
          flex-direction: column;
          gap: 5px;
          
          color: rgba(107, 114, 128, 1);
          .spanBig {
            font-size: 18px;
            font-weight: 500;
            color: rgba(107, 114, 128, 1);
          }
        }
      }
      .descSquer {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        color: rgba(107, 114, 128, 1);
        .span1 {
          width: 120px;
          font-size: 18px;
          font-weight: 500;
          color: rgba(107, 114, 128, 1);
          padding: 0;
        }
      }
    }
  }
  span{
    font-size: 20px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .footer {
    max-width: 100vw;
    width: 100%;
    .footer_wrap {
      width: 990px;
      margin: 0 auto;
      .footer_content {
        width: 990px;
      }
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .footer {
    max-width: 100vw;
    width: 100%;
    .footer_wrap {
      width: 800px;
      // margin: 0 auto;
      .footer_content {
        width: 800px;
        gap: 10px;
        .someWords {
          .square {
            width: 200px;
          }
        }
        .squareDark {
          width: 200px;
        }
      }
    }
  }
}
@media screen and (min-width: 426px) and (max-width: 768px) {
  .footer {
    max-width: 100vw;
    width: 100%;
    .footer_wrap {
      width: 400px;
      margin: 0 auto;
      padding: 0;
      // text-align: center;
      .footer_content {
        width: 400px;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        gap: 20px;
        .menu {
          nav {
            display: flex;
            flex-direction: row;
            gap: 10px;
            padding-top: 10px;
          }
        }
        .contacts {
          text-align: center;
        }
        // p0
      }
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 426px) {
  .footer {
    max-width: 100vw;
    width: 100%;
    .footer_wrap {
      width: 375px;
      margin: 0 auto;
      padding: 0;
      .span1 {
        font-size: 15px;
      }
      .footer_content {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        gap: 5px;
        .menu {
          nav {
            display: flex;
            flex-direction: row;
            gap: 10px;
            padding-top: 10px;
          }
        }
        .contacts {
          text-align: center;
          gap: 5px;
          .spanBig{
            font-size: 25px;
          }
        }
        .descSquer {
          text-align: center;
          .span1{
            font-size: 25px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 375px) {
  .footer {
    max-width: 100vw;
    width: 100%;
    
    .span1{
      font-size: 20px;
      max-width: 300px;
    }
    .footer_wrap {
      width: 320px;
      margin: 0 auto;
      padding: 0;
      
      .footer_content {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        gap: 5px;
        .someWords{
          img{
            width: 200px;
          }
        }
        .menu {
          nav {
            display: flex;
            flex-direction: row;
            gap: 10px;
            padding-top: 10px;
          }
        }
        .contacts {
          text-align: center;
          gap: 5px;
        }
        .descSquer {
          text-align: center;
        }
      }
    }
  }
}