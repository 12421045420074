@import "/src/source/mixin.scss";
.main {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
    url("./assets/bakg.png");
  height: 850px;
  background-repeat: no-repeat;
  background-position: center;
  // position: relative;  
  overflow: hidden;
  margin: 0 auto;
  @include screen(426px){
    height: 550px;
  }
  @include screen(376px){
    height: 450px;
  }
  .title {
    // position: absolute;
    margin-top: 37px;
    width: 300px;
    background: rgba(31, 120, 225, 1);
    padding:0 15px;
    border-radius: 0 82px 82px 0;
    // top: 100px;
    // left: -105px;
    text-align: end;
    @include screen(426px){
      width: 426px;
      border-radius: 0;
      text-align: center;
      margin: 0;
    }
    @include screen(376px){
      width: 100%;
    }
    // @include screen(320px){
    //   width: 320px;
    // }
    
    h1 {
      color: #fff;
      // font-size: 28px;
      font-size: 52px;
      font-weight: 300;
      line-height: 78px;
      @include screen(376px){
        font-size: 29px;
        line-height: 54px;
      }
    }
  }
  .backBlock {
    background: transparent;
    max-width: 100vw;
    height: 705px;
    color: white;
    display: flex;
    align-items: end;
    justify-content: center;
    text-align: center;
    @include screen(426px){
      align-items: start;
      height: 400px;
    }
    ol {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 50px;
      @include screen(426px){
        gap: 30px;
      }
      @include screen(376px){
        gap: 10px;
      }
      @include screen(320px){
        gap: 10px;
      }
      
      h2 {
        font-weight: 600;
        font-size: 45px;
        line-height: 67.5px;
        text-align: center;
        @include screen(426px){
          line-height: 40px;
          font-size: 30px;
        }
        @include screen(376px){
          font-size: 20px;
        }
        @include screen(320px){
          font-size: 20px;
        }
      }
      a,
      span {
        font-size: 35px;
        font-weight: 500;
        line-height: 45px;
        text-decoration: none;
        // line-height: 67.5px;
        color: inherit;
        line-height: 45.5px;
        cursor: pointer;
        // width: 100%;
        // max-width: 100vw;
        @include screen(426px){
          line-height: 35px;
          font-size: 20px;
        }
      }
      span{
        @include screen(376px){
          font-size: 18px;
        }
      }
    }
  }
}
